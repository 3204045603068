<template lang="pug">
    base-layout
        template(slot="main")
            div.title личный кабинет
            div.control
                p.control__text Привет,  {{ loggedInUser && loggedInUser.data && loggedInUser.data.name ? loggedInUser.data.name : '' }}
                p.control__text.control-second Для участия в акции необходимо ввести номер на авто, который вы поймали в эфире телеканал RU.TV
                //- input.input-pink(type="text", placeholder="ввести номер" v-model="number" v-mask="'XX XXX XX'" @input="checkAfterSec()" v-on:keyup.enter="onEnterNumber()" )

            div.data(v-if="!isLoading")
                div.data__title(v-if="used_numbers.length") ваши зарегистрированные номера
                div.data__numbers
                    div.numbers__el(v-for="(num, key) in used_numbers"  v-bind:data="key" v-bind:key="key") {{ splitNum(num) }}  
            div.loader
                div.lds-dual-ring(v-if="isLoading")

            div.logout
                button.button-white(@click="logOut()") выйти из лк
</template>

<script>
//Components
import BaseLayout from '@/layout/base-layout';
import { NumberItemNameSpace, NumberItemActionTypes } from '@/store/number-item/types';
import $ from 'jquery'

export default {
    name: "account",
    components: {
        'base-layout': BaseLayout
    },
    data() {
        return {
            number: '',
            counter: '',
            used_numbers: [],
            loggedInUser: null,
            timeOut: null,
            isLoading: false,
            is500: false
        }
    },
    mounted(){
        this.getNumbers()
        this.loggedInUser = JSON.parse(localStorage.getItem('authData'))
        this.is500        = window.innerWidth <= 500
        if (this.is500) {
            $('html, body').animate({scrollTop: 570}, 300);
        }
    },
    methods: {
        splitNum(num){
            let array = num.split(/\B\d\B/);

            (array || []).forEach(n => {
                if (n) {
                    num = num.replace(n, ' ' + n + ' ')
                }
            });

            return num.trim()
        },
        logOut(){
            localStorage.removeItem('authData')
            localStorage.removeItem('loggedIn')
            window.location.replace("/");
        },
        checkAfterSec(){
            clearTimeout(this.timeOut)
            this.timeOut = setTimeout(() => {
                if (this.number && this.number.replace(/\s/g, "").length == 7) {
                    this.onEnterNumber()
                }
            }, 1500)
        },
        async onEnterNumber() {
            if(!this.number){
                return true
            }

            this.isLoading = true
            await this.$store.dispatch(`${NumberItemNameSpace}/${NumberItemActionTypes.CountNumberItem}`, { data: { number: this.number.replace(/\s/g, "") } }).then(() => {
                this.getNumbers()
            })
        },
        getNumbers(){
            this.$store.dispatch(`${NumberItemNameSpace}/${NumberItemActionTypes.GetUserNumberCount}`).then(({data}) => {
                if(data?.data?.number_counter){
                    this.counter      = data?.data?.number_counter
                    this.used_numbers = data?.data?.used_numbers
                    this.number       = ''
                }

                this.isLoading = false
            })
        },
        
    },
}
</script>

<style lang="scss" scoped>
    .logout{
        text-align: center;
        margin: 20px 0px 0px 0px;
    }

    .loader{
        max-width: 80px;
        margin: auto;
    }

    .title{
        font-weight: 900;
        font-size: 35px;
        text-align: center;
        text-transform: uppercase;
        color: #E4007A;
        margin: 10px 0px 60px 0px;
        @media (max-width:500px){
            font-size: 30px;
        }
    }

    .control{
        text-align: center;
        .control__text{
            font-weight: 900;
            font-size: 28px;
            text-align: center;
            margin-bottom: 15px;
            text-transform: uppercase;
            color: #000000;
            @media (max-width:500px){
                font-size: 16px;
                max-width: 300px;
                margin: auto;
            }
        }

        .control-second{
            max-width: 670px;
            margin: auto;
        }

        .input-pink{
            font-weight: 900;
            font-size: 28px;
            text-align: center;
            text-transform: uppercase;
            color: #9C9A9A;
            margin-top: 50px;
            max-width: 360px;
            margin-bottom: 50px;
            @media (max-width:500px){
                font-size: 16px;
                max-width: 300px;
                padding: 5px 5px 2px 5px;
                margin: 40px 0px 25px 0px;
                height: 45px;
            }
        }
    }

    .data{
        margin-top: 60px;
        @media (max-width:500px){
            margin-top: 25px;
        }
        .data__title{
            font-weight: 900;
            font-size: 35px;
            text-align: center;
            text-transform: uppercase;
            color: #E4007A;
            margin-bottom: 40px;
            @media (max-width:500px){
                font-size: 28px;
                margin-left: auto;
                margin-right: auto;
                max-width: 350px;
                line-height: 30px;
            }
        }

        .data__numbers{
                max-width: 910px;
                margin-left: auto;
                display: flex;
                margin-right: auto;
                flex-direction: row;
                flex-wrap: wrap;
            .numbers__el{
                font-weight: 900;
                font-size: 40px;
                text-align: center;
                text-transform: uppercase;
                color: #000000;
                padding-right: 50px;
                padding-left: 50px;
                margin-bottom: 15px;
                @media (max-width:500px){
                    font-size: 30px;
                    margin: auto;
                }
            }
        }
    }

</style>